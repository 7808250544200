import React, { Component } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { theme } from "../utils/theme";
import posed from 'react-pose';
import { Helmet } from 'react-helmet';
import Contact from "../components/contact";

const GlobalStyle = createGlobalStyle`
    body {
        padding: 0;
        margin: 0;

        font-family: 'Cardo', serif;
        font-weight: ${({ theme }) => theme.font.book};
        font-style: normal;

        font-size: 16px;

        color: ${({ theme }) => theme.colors.black};
    }

    p {
        line-height: 1.8rem;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.secondary};
    }
`;


const Navigation = styled.div`
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 10;
    top: 0px;
    left: 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1.5rem;
    background: rgba(255,255,255,0.95);

    ${({ theme }) => theme.media.medium} {
      background: rgba(255,255,255,0.95);
    }

    ${({ theme }) => theme.media.desktop} {
        padding-top: 3rem;
        padding-right: 4rem;
        background: none;
     }
    
     ${({ theme }) => theme.media.investitionsDesktop} {
        background: none;
     }
`;

const PosedButton = posed.a({
  hoverable: true,

  init: {
    color: '#000',
    'border-color': '#000',
  },
  hover: {
    color: '#fe0000',
    'border-color': '#fe0000',
  },
});

const Button = styled(PosedButton)`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    color: ${({ theme }) => theme.colors.black};
    border: 2px solid ${({ theme }) => theme.colors.black};
    border-radius: ${({ isContactActive }) => isContactActive ? '25px' : '30px'};
    padding: ${({ isContactActive }) => isContactActive ? '0px 10px' : '0px 24px'};
    transition: 2s easy-in-out;
    line-height: 2rem;

    ${({ isContactActive }) => isContactActive ? 'line-height: 2rem' : ''};

    ${({ theme }) => theme.media.desktop} {
        padding: ${({ isContactActive }) => isContactActive ? '15px 15px' : '12px 32px'};
        font-size: 1rem;
        line-height: 0.8rem;
     }
`;

const Logo = styled.img`
    position: absolute;
    left: 1.5rem;
    top: 1.2rem;
    height: 32px;

    ${({ theme }) => theme.media.desktop} {
        left: 4rem;
        top: 2.5rem;
        height: 45px;
    }
`;

class Layout extends Component {
  state = {
    isContactActive: false,
  }

  toggleContact = () => {
    this.setState(prevState => ({ isContactActive: !prevState.isContactActive }));
  }

  render() {
    const { children } = this.props;
    const { isContactActive } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <>
          <Helmet>
            <title>KOPAX Budownictwo</title>
            <meta name="description" content="" />
            <link href="https://fonts.googleapis.com/css?family=Montserrat:200,400,600,900&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Cardo:400,700&display=swap" rel="stylesheet" />
            <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
          </Helmet>
          <GlobalStyle />
          <Navigation>
            <a href="/#">
              <Logo src="/images/kopax_logo.png" alt="KOPAX Budownictwo" />
            </a>
            <Button href="#" onClick={this.toggleContact} isContactActive={isContactActive}>
              {
                isContactActive
                  ? <span class="lnr lnr-cross"></span>
                  : 'Kontakt'
              }
            </Button>
          </Navigation>
          {children}
          <Contact isContactActive={isContactActive} />
        </>
      </ThemeProvider>
    )
  }
}

export default Layout;