import React, { Component } from "react";
import styled from "styled-components";
import posed from 'react-pose';

const TileContainer = styled.div`
    display: block;
    margin-top: 12rem;
    padding: 0 1rem;

    ${({ theme }) => theme.media.medium} {
      padding: 0 2rem;
    }
`;

const TileHeader = styled.h1`
    font-weight: 600;
    margin: 0;
`;


const TileSubContent = styled.p`
  margin: 0;
  padding-bottom: 0.2rem;
  font-weight: 400;
  font-size: calc(100% + 2px);
`;

const TileSubContentAdditionalInfo = styled.p`
    margin: 0;
    padding-bottom: 3rem;
    font-weight: 100;
    font-size: 12px;
`;

const TileSubContentMail = styled.a`
    text-decoration: none;
    color: #000;

    &:hover {
      text-decoration: underline;
      transition: all 10s ease-in-out 0s;
    }
`;

const TileSubContentPhone = styled.a`
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
    transition: all 0.5s;
  }
`;


const PosedContactWrapper = posed.div({
  visible: {
    x: '0%',
    staggerChildren: 100,
  },
  hidden: {
    x: '100%',
  }
});

const ContactWrapper = styled(PosedContactWrapper)`
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    position: fixed;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
`;

const ContactTileImage = styled.div`
    min-height: 100vh;
    position: relative;
    background: rgba(0,0,0,0.4);
    width: 75%;
    display: none;

    ${({ theme }) => theme.media.investitionsDesktop} {
        display: flex;
        width: 75%;
    }
`;

const ContactTileContent = styled.div`
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: auto;
    background: ${({ theme }) => theme.colors.white};

    ${({ theme }) => theme.media.investitionsDesktop} {
        display: flex;
        width: 25%;
    }
`;

const Copywright = styled.h4`
    bottom: 0;
    left: 0;
    padding: 0 1rem;
    text-decoration: none;
    font-weight: 300;
    font-size: 10px;
    
    ${({ theme }) => theme.media.investitionsDesktop} {
      padding: 0.5rem 2rem;
      position: absolute;
    }
`;

const Address = styled.address`
  margin-bottom: 3rem;
  font-style: normal;
`;


class Contact extends Component {
  render() {
    return (
      <ContactWrapper isContactActive={this.props.isContactActive} pose={this.props.isContactActive ? 'visible' : 'hidden'}>
        <ContactTileImage />
        <ContactTileContent>
          <TileContainer>
            <TileHeader>KOPAX Sp.&nbsp;z&nbsp;o.o.</TileHeader>
            <TileSubContentAdditionalInfo>
              NIP 635 184 71 85 &nbsp; REGON 380243780
            </TileSubContentAdditionalInfo>
            <Address>
              <TileSubContent>
                ul. Wspólna 3,
              </TileSubContent>
              <TileSubContent>
                43-190 Mikołów
              </TileSubContent>
            </Address>
            <TileSubContent>
              <TileSubContentPhone href="tel:+48780166480">+48 780 166 480</TileSubContentPhone>
            </TileSubContent>
            <TileSubContent>
              <TileSubContentMail href="mailto:kontakt@kopax.eu">kontakt@kopax.eu</TileSubContentMail>              
            </TileSubContent>
            <TileSubContent>
              <TileSubContentMail href="mailto:biuro@kopax.eu">biuro@kopax.eu</TileSubContentMail>
            </TileSubContent>
            <br />
          </TileContainer>
          <Copywright>
            &copy; 2019 KOPAX
          </Copywright>
        </ContactTileContent>
      </ContactWrapper>
    )
  }
}

export default Contact;