import {colors} from './colors';

export const theme = {
    colors,
    font: {
        light: 300,
        book: 400,
        medium: 500,
        bold: 700,
    },
    size: {
        lead: 'calc(100% + 2px)',
    },
    media: {
        mobile: '@media (min-width: 400px)',
        tablet: '@media (min-width: 768px)',
        medium: '@media (min-width: 1145px) and (min-height: 700px)',
        desktop: '@media (min-width: 1240px) and (min-height: 930px)',
        investitionsDesktop : '@media (min-width: 1240px)',
    },
}