export const sections = [
    {
        name: "industry",
        heading: "Budownictwo przemysłowe",
        text: "Kompleksowo realizujemy inwestycje od wykonania projektu, przez roboty budowlane, aż do uzyskania pozwolenia na użytkowanie. Prowadzimy nadzór oraz zapewniamy kierowników budowy oraz robót. Posiadamy uprawnienia do przeprowadzania okresowych kontroli obiektów budowlanych.",
        mainImage: "images/illustrations/budownictwo-przemyslowe.svg",
        linkText: "Dowiedz się więcej",
        link: "/budownictwo-przemyslowe",
        secondLinkText: "Zobacz obiekty na wynajem",
        secondLink: "/obiekt-na-wynajem",
    },
    {
        name: "buildings",
        heading: "Budownictwo mieszkaniowe",
        text: "Realizujemy budynki mieszkaniowe jednorodzinne, wielorodzinne oraz zespoły obiektów - bliźniaki lub w zabudowie szeregowej. Dzięki współpracy z grupą architektów oraz konstruktorów możemy zaprojektować lub zaadaptować projekt budowlany według potrzeb klienta.",
        mainImage: "images/illustrations/budownictwo-mieszkaniowe.svg",
        linkText: "Dowiedz się więcej",
        link: "/budownictwo-mieszkaniowe",
        secondLinkText: "Zobacz budynki na sprzedaż",
        secondLink: "/budynek-na-sprzedaz",
    },
    {
        name: "oldBuildings",
        heading: "Remonty oraz renowacje",
        text: "Zajmujemy się także remontami obiektów budowlanych, przebudową oraz ich rozbudową. Mamy doświadczenie w robotach prowadzonych w strefie ochrony konserwatorskiej oraz pod nadzorem archeologicznym.",
        mainImage: "images/illustrations/renowacja-remonty.svg",
        linkText: "Dowiedz się więcej",
        link: "/remonty-oraz-renowacje",
    },
    {
        name: "tools",
        heading: "Usługi sprzętowe",
        text: "Oferujemy usługi sprzętem budowlanym. Posiadamy koparko-ładowarkę oraz samochody ciężarowe. Wykonujemy roboty ziemne pod budynki, place i drogi dojazdowe. Wykonujemy podbudowę oraz nawierzchnię placów i chodników.",
        mainImage: "images/illustrations/uslugi-sprzetowe.svg",
    },
];