import React, { Component } from "react";
import Layout from "../layout/Layout";
import Vivus from "vivus";
import styled from "styled-components";
import { sections } from '../data/sections';

const Wrapper = styled.div`
    display: block;
    padding-top: 68px;
    background: ${({theme}) => theme.colors.white};
    

    ${({theme}) => theme.media.medium} {
      display: flex;
      background: ${({theme}) => theme.colors.white};
    }

    ${({theme}) => theme.media.investitionsDesktop} {
        display: flex;
        padding-top: 0;
        background: ${({theme}) => theme.colors.gray};
    }
`;

const Tile = styled.div`
    width: 100%;
    position: relative;

    ${({theme}) => theme.media.medium} {
      width: 100%;
    }

    ${({theme}) => theme.media.investitionsDesktop} {
      min-height: 100vh;
      width: 25%;

      &:after {
        border-bottom: 10px solid #e8e8e8;
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
      }
      
      object {
        opacity: .7;
      }

        &:hover {
          background: #fff;
          bottom: 0;

          object {
            opacity: 1;
            transition: all .6s;
          }

          &:after {
            content: "";
            border-bottom: 10px solid red;
            position: absolute;
            display: block;
            width: 100%;
            bottom: 0;
          }
        }
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: left;

    ${({theme}) => theme.media.mobile} {
      justify-content: left;
    }
    ${({theme}) => theme.media.medium} {
      justify-content: center;
    }

    ${({theme}) => theme.media.desktop} {
      margin-bottom: 2rem;
      justify-content: center;
    }

    ${({theme}) => theme.media.investitionsDesktop} {
      margin-bottom: 2rem;
      justify-content: center;
    }
`;

const TileContainer = styled.div`
    padding: 3rem 1.5rem;

    ${({theme}) => theme.media.desktop} {
        padding: 0 3.5rem;
        min-height: 800px;
        margin-bottom: 4rem;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
`;

const TileImage = styled.object`
    width: auto;
    height: 220px;

    ${({theme}) => theme.media.desktop} {
      width: auto;
      height: auto;
      max-height: 350px;
      max-width: 100%;
  }
`;

const TileContent = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: ${({theme}) => theme.colors.black};
    font-size: 36px;

    ${({theme}) => theme.media.desktop} {
      font-size: 42px;
    }
`;

const TileSubContent = styled.p`
    color: ${({theme}) => theme.colors.black};
`;

const TileLink = styled.a`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: ${({theme}) => theme.colors.red};
  text-decoration: underline;
`;


const LinkContainer = styled.div`
    padding: 5px 0;
`;

const Sections = props => (
  <Tile name={props.item.name}>
    <TileContainer>
      <ImageContainer>
          <TileImage id={`illustration-${props.item.name}`} type="image/svg+xml" data={props.item.mainImage}></TileImage>
      </ImageContainer>
      <TileContent>{props.item.heading}</TileContent>
      <TileSubContent>{props.item.text}</TileSubContent>
      <LinkContainer>
        <TileLink href={props.item.link}>{props.item.linkText}</TileLink>
      </LinkContainer>
      <LinkContainer>
        <TileLink href={props.item.secondLink}>{props.item.secondLinkText}</TileLink>
      </LinkContainer>
    </TileContainer>
  </Tile>
)

class MainPage extends Component {
  componentDidMount() {
    sections.map((section) => new Vivus(`illustration-${section.name}`, { duration: 100 }, () => {}));
  }

  render() {
    return (
      <Layout name="section-home">
        <Wrapper>    
          {
            sections.map((section, index) => (
              <Sections key={index} item={section} />
            ))
          }
        </Wrapper>
      </Layout>
    )
  }
}

export default MainPage;